@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';

@layer components {
	.aiq-form-title {
		@apply block font-bold text-lg text-black mb-6;
	}

	.aiq-form-section-title {
		@apply block font-medium text-neutral-700 mb-3;
	}

	.aiq-card-section-title {
		@apply block font-bold text-neutral-900 mb-4;
	}

	.aiq-card-title {
		@apply font-bold text-neutral-600;
	}

	.aiq-subtitle {
		@apply text-sm text-neutral-600;
	}

	.aiq-subtitle-xs {
		@apply block text-xs text-neutral-600;
	}

	.aiq-card-header {
		@apply font-bold text-black text-lg;
	}

	.flex-row-center {
		@apply flex flex-row items-center;
	}

	.font-lobster {
		font-family: 'Lobster', sans-serif;
	}

	.alert-text {
		@apply text-xs block;
	}

	.error-text {
		@apply alert-text;
		@apply text-error;
	}

	.text-warning {
		@apply alert-text;
		@apply text-orange-500;
	}

	.text-error {
		@apply alert-text;
		@apply text-error-red;
	}

	.text-success {
		@apply alert-text;
		@apply text-green-600;
	}
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); */

html,
body {
	padding: 0;
	margin: 0;
	font-size: 15px;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
	/* overflow-x: hidden; */
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

/* Hide arrows in input[type=number] */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

select {
	padding: 10px;
	padding-right: 30px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position:
		right 0.7em top 50%,
		0 0;
	background-size:
		0.65em auto,
		100%;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
	display: none;
}

/* Override z index for leaflet container*/
.leaflet-container {
	z-index: 1;
}

/* Loader css*/

.loader,
.loader:after {
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 4px solid rgba(1, 144, 255, 0.2);
	border-right: 4px solid rgba(1, 144, 255, 0.2);
	border-bottom: 4px solid rgba(1, 144, 255, 0.2);
	border-left: 4px solid #0190ff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 0.7s infinite linear;
	animation: load8 0.7s infinite linear;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Loader css*/
